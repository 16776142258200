.mobile {
    background-color: white;
    height: 1118vw;

    .background {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        width: 100vw;
        height: auto;
        padding: 0;
        margin: 0;
        border: 0;
    }

    .header-bg {
        background-color: rgb(163, 202, 232);
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 15.625vw;
        padding: 0;
        margin: 0;
        border: 0;
    }

    div.contactus {
        background-color: rgb(47, 163, 223);
    }

    div.contactus:hover {
        background-color: white;
        color: rgba(1, 33, 105, 1);
    }

    .email-link {
        color: rgba(1, 33, 105, 1);
        text-decoration: inherit;
    }

    .email-link:hover {
        color: rgba(214, 135, 248, 1);
        cursor: pointer;
    }

    a.link {
        color: inherit;
        text-decoration: inherit;
    }

    a.link:hover {
        color: rgba(214, 135, 248, 1);
        cursor: pointer;
    }
}