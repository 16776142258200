@font-face {
  font-family: 'Fellix,sans-serif,Arial';
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/Fellix-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Fellix,sans-serif,Arial';
  font-style: normal;
  font-weight: 600;
  src: url(./fonts/Fellix-Semibold.woff) format('woff');
}

@font-face {
  font-family: 'Fellix,sans-serif,Arial';
  font-style: normal;
  font-weight: 700;
  src: url(./fonts/Fellix-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Fellix,sans-serif,Arial';
  font-style: normal;
  font-weight: 800;
  src: url(./fonts/Fellix-ExtraBold.otf) format('opentype');
}

@font-face {
  font-family: 'Fellix,sans-serif,Arial';
  font-style: normal;
  font-weight: 900;
  src: url(./fonts/Fellix-Black.otf) format('opentype');
}

.fellix {
  text-decoration: none;
  text-transform: none;
  color: white;
  font-family: 'Fellix,sans-serif,Arial';
  font-style: normal;
}

.fellix--regular, .fellix--400 {
  @extend .fellix;
  font-weight: 400;
}

.fellix--medium, .fellix--600 {
  @extend .fellix;
  font-weight: 600;
}

.fellix--bold, .fellix--700 {
  @extend .fellix;
  font-weight: 700;
}

span.fellix {
  white-space: pre;
  margin: 0;
  padding: 0;
  border: 0;
}
